import { ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { combineLatest, from, of } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { CodeValueGroups, CodeValueService, PhxConstants } from '@common';
import { PhxDialogComponentConfigModel } from '@common/components/phx-dialog/phx-dialog.component.model';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import { OrganizationDataService } from '@common/data-services/organization-data/organization-data.service';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { ICommonListsItem } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { CodeValue } from '@common/model/code-value';
import { FormGroup } from '@common/ngx-strongly-typed-forms';
import { LookupPipe } from '@common/pipes/lookup.pipe';
import { AuthService } from '@common/services/auth.service';
import { ConfigurationService } from '@configuration/service/configuration.service';
// TODO: this import couples the service with the contact module, we should move this to a shared service or find a better way to handle this
import { IProfile } from '@contact/models/profile.interface';
import { ProfileService } from '@contact/services/profile.service';
// TODO: this import couples the service with the statholiday module, we should move this to a shared service or find a better way to handle this
import { Holiday } from '@statholiday/model/holiday.module';

import { ControlFieldAccessibility } from '../../control-field-accessibility';
import { IFormGroupSetup, IReadOnlyStorage, ITabCoreDetails, IWorkOrder, IWorkOrderVersion } from '../../models';
import { CoreDetailFormService, WorkOrderFormService, WorkorderService } from '../../services';

@Component({
  selector: 'app-workorder-tab-core-details',
  templateUrl: './workorder-tab-core-details.component.html',
  styleUrls: ['./workorder-tab-core-details.component.less']
})

export class WorkorderTabCoreDetailsComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  readonly phxConstants = PhxConstants;
  readonly hasTheOptionToExcludeFromBIReport$ = from(inject(AuthService).getUserContext()).pipe(map(context => context.User.CurrentTenantName === 'Procom'));

  formGroup: FormGroup<ITabCoreDetails>;

  formGroupSetup: IFormGroupSetup;

  workOrder: IWorkOrder;
  isDraftStatus: boolean;
  isComplianceDraftStatus: boolean;
  isDisplayWorkOrderStartDateState: boolean;
  isDisplayWorkOrderEndDateState: boolean;
  workorderEndDateLabelText: string;
  phxDialogComponentConfigModel: PhxDialogComponentConfigModel;

  holidayList: Array<Holiday> = [];
  holidayItemList: Array<ICommonListsItem> = [];
  userProfile: IProfile | null = null;

  html = {
    lists: {
      lineOfBusiness: [] as Array<CodeValue>,
      InternalOrganizationDefinition1List: [] as Array<{ id: number; value: string }>,
      workOrderWorkLocations: [] as Array<CodeValue>,
      workerLocations: [] as Array<CodeValue>,
      OrganizationInternalList: [] as Array<{ Code: string; IsTest: boolean; OrganizationIdInternal: number; Name: string; DisplayValue: string }>,
      holidayScheduleNameList: [] as Array<ICommonListsItem>
    }
  };

  onloadHasNoWorksiteOrWorkerLocation: boolean;
  onloadHasWorkerLocation: boolean;
  hideCommissions = false;

  @ViewChild('itemModal', { static: true }) itemModal: PhxModalComponent;
  @ViewChild('phxDialogComponent', { static: true }) phxDialogComponent: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private codeValueService: CodeValueService,
    private workOrderFormService: WorkOrderFormService,
    private workorderService: WorkorderService,
    private coreDetailFormService: CoreDetailFormService,
    private commonListsObservableService: CommonListsObservableService,
    private authService: AuthService,
    private lookupPipe: LookupPipe,
    private profileService: ProfileService,
    public organizationDataService: OrganizationDataService,
    private configurationService: ConfigurationService
  ) {
    super();
    this.getLists();
    this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.HideCommissions]).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(featureFlagState => {
      this.hideCommissions = featureFlagState[PhxConstants.FeatureFlags.HideCommissions];
    });
  }

  get calendarName() {
    return this.coreDetailFormService.holidayScheduleNameIdFormControl;
  }

  ngOnInit() {
    // When a new work order was created, the holidayScheduleNames in the WorkOrderFormService
    // wouldn't always load, so if the Worker Location was changed, the Holiday Schedule wouldn't update.
    // Calling this here fixes that.
    this.workOrderFormService.loadHolidayScheduleNames();

    this.formGroup = this.coreDetailFormService.formGroup;
    this.coreDetailFormService.workerLocationIdFormControl.setValidators([Validators.required]);
    this.setupFormGroupListeners();

    this.workOrderFormService.workOrder$.pipe(takeUntil(this.isDestroyed$)).subscribe((workOrder: IWorkOrder) => {
      if (workOrder) {
        this.workOrder = workOrder;

        const { WorkOrderVersion: { WorksiteId, WorkerLocationId, BillingInfoes } } = workOrder;
        if (!WorksiteId && !WorkerLocationId) {
          this.onloadHasNoWorksiteOrWorkerLocation = true;
        }
        if (WorkerLocationId) {
          this.onloadHasWorkerLocation = true;
        }

        this.initLineOfBusinessList(BillingInfoes?.length ? BillingInfoes[0].OrganizationIdClient : 0);

        this.controlDisplayStatus();
        this.getUserProfile(() => {
          this.initWorksitesAndWorkerLocations();
        });
      }
    });

    this.phxDialogComponentConfigModel = {
      HeaderTitle: 'The Internal Company Has Changed',
      // body message moved to html
      // BodyMessage: 'The Workplace Safety Insurance Worker Classification list and Source Deductions Province/State has been updated.',
      ShowCustomBody: true,
      Buttons: [
        {
          Id: 1,
          SortOrder: 1,
          CheckValidation: true,
          Name: 'Okay',
          Class: 'btn-primary',
          ClickEvent: () => {
          }
        }
      ],
      ObjectDate: null,
      ObjectComment: null
    };
    this.getInternalOrganization();
    this.getHolidayScheduleNames();
  }

  getLists(): void {
    combineLatest([
      of(this.codeValueService.getCodeValues(CodeValueGroups.InternalOrganizationDefinition1, true)),
      this.workorderService.getBranchList()
    ]).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(([branchCodeValues, branchList]) => {
      this.html.lists.InternalOrganizationDefinition1List = [];
      branchCodeValues.forEach(branchCode => {
        const branchDetails = branchList?.Items.find(f => f.Id === branchCode.id);
        this.html.lists.InternalOrganizationDefinition1List.push({ id: branchCode.id, value: `${branchCode.text}-${branchCode.code} ${branchDetails?.IsActive === false ? '(Inactive)' : ''}` });
      });
    });
  }

  private initLineOfBusinessList(organizationId: number): void {
    const lobCodeValues = this.getLOBCodeValues();

    if (!organizationId) {
      this.html.lists.lineOfBusiness = lobCodeValues;
      return;
    }

    this.organizationDataService.getClientRoleSelectedLOBCodes(organizationId)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((lobs: string[]) => {
        if (lobs?.length) {
          const lobsSet = new Set(lobs);
          this.html.lists.lineOfBusiness = lobCodeValues.filter(lob => lobsSet.has(lob.code));

          const { LineOfBusinessId } = this.formGroup.controls;

          // Add back any missing value for previously selected LOBs
          if (LineOfBusinessId.value && this.html.lists.lineOfBusiness.find((elem: CodeValue) => elem.id === LineOfBusinessId.value) === undefined) {
            this.html.lists.lineOfBusiness = [...this.html.lists.lineOfBusiness, lobCodeValues.find((elem: CodeValue) => elem.id === LineOfBusinessId.value)];
          }

        } else {
          this.html.lists.lineOfBusiness = lobCodeValues;
        }
      });
  }

  private getLOBCodeValues(): CodeValue[] {
    return this.codeValueService.getCodeValues(CodeValueGroups.LineOfBusiness, true)
      .filter(o => o.id !== PhxConstants.LineOfBusiness.PermPlacement && o.id !== PhxConstants.LineOfBusiness.Expense);
  }

  private getUserProfile(onCompleteCallback: () => void): void {
    this.profileService.get(this.workOrder.UserProfileIdWorker)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((profile: any) => {
        this.userProfile = profile;
        onCompleteCallback();
      });
  }

  private initWorksitesAndWorkerLocations(): void {
    if (!this.userProfile) {return;}

    // No restrictions on the worksites
    this.html.lists.workOrderWorkLocations = this.getAllWorksites();

    // Restrict the worker locations to those within the users's country:
    if (this.isTempOrSpWorker()) {
      this.html.lists.workerLocations = this.getSubdivisionsByCountry(PhxConstants.CountryCanada);
    } else if (this.isW2Worker()) {
      this.html.lists.workerLocations = this.getSubdivisionsByCountry(PhxConstants.CountryUSA);
    } else {
      this.html.lists.workerLocations = this.getAllSubdivisions();
    }

    this.cdr.detectChanges();
  }

  controlDisplayStatus(): void {
    this.isDraftStatus = this.workOrder.WorkOrderVersion.IsDraftStatus;
    this.isComplianceDraftStatus = this.workOrder.WorkOrderVersion.IsComplianceDraftStatus;
    this.isDisplayWorkOrderStartDateState = this.coreDetailFormService.displayWorkOrderStartEndDateState(this.workOrder);
    this.isDisplayWorkOrderEndDateState = this.coreDetailFormService.displayWorkOrderStartEndDateState(this.workOrder);
    this.changeWOEndDateLabelText();
  }

  getHolidayScheduleNames(): void {
    this.commonListsObservableService.listHolidayScheduleName$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        this.html.lists.holidayScheduleNameList = response || [];
        this.holidayItemList = this.html.lists.holidayScheduleNameList.filter(n => n.Data.IsActive === true);
      });
  }

  openSchedule(): void {
    this.holidayList = this.html.lists.holidayScheduleNameList
      .find(i => i.Id === this.calendarName.value).Data.HolidayList;
    this.holidayList.sort(this.sortByCurrentYearDate);
    this.itemModal.show();
  }

  getInternalOrganization() {
    this.commonListsObservableService.listOrganizationInternals$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        const list = response ? response.map(item => item.Data) : [];
        if (list.length > 0) {
          this.html.lists.OrganizationInternalList = list.map((value: any) => {
            return {
              Code: value.Code,
              IsTest: value.IsTest,
              OrganizationIdInternal: value.Id,
              Name: value.DisplayName,
              DisplayValue: value.DisplayName + ' - ' + value.Id
            };
          });
        }
      });
  }

  changeWOEndDateLabelText(): void {
    if (
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTermination ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTerminationNotice ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Terminated
    ) {
      this.workorderEndDateLabelText = 'Original End Date';
    } else {
      this.workorderEndDateLabelText = 'End Date';
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string, modelValidation: null | IWorkOrderVersion = null): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService, modelValidation);
  }

  worksiteSupportsHolidaySchedule(worksiteId: number): boolean {
    if (worksiteId) {
      const country = this.getWorksiteCountryId(worksiteId);
      return country === PhxConstants.CountryCanada || country === PhxConstants.CountryUSA;
    }
    return false;
  }

  workerLocationSupportsHolidaySchedule(workerLocationId: number): boolean {
    if (workerLocationId) {
      const countryId = this.codeValueService.getCodeValue(workerLocationId, 'geo.CodeSubdivision').parentId;
      return countryId === PhxConstants.CountryCanada || countryId === PhxConstants.CountryUSA;
    }
    return false;
  }

  shouldShowHolidaySchedule(): boolean {
    const { WorksiteId, WorkerLocationId } = this.formGroup.controls;

    const hasSupportedWorksite = Boolean(WorksiteId?.value && this.worksiteSupportsHolidaySchedule(WorksiteId.value));
    const hasSupportedWorkerLocation = Boolean(WorkerLocationId?.value && this.workerLocationSupportsHolidaySchedule(WorkerLocationId.value));

    if (this.onloadHasNoWorksiteOrWorkerLocation || this.onloadHasWorkerLocation) {
      return hasSupportedWorkerLocation;
    }

    // Support backward-compatibility of showing the holiday schedule for existing work orders that have a work site but no worker location
    return hasSupportedWorksite || hasSupportedWorkerLocation;
  }

  private getWorksiteCountryId(worksiteId: number): number {
    const subDivisionId = this.codeValueService.getParentId(CodeValueGroups.Worksite, worksiteId);
    const countryId = this.codeValueService.getCodeValue(subDivisionId, 'geo.CodeSubdivision').parentId;
    return countryId;
  }

  private getAllWorksites(): CodeValue[] {
    return this.codeValueService.getCodeValues(CodeValueGroups.Worksite, true);
  }

  private getAllSubdivisions(): CodeValue[] {
    const subdivs = this.codeValueService.getCodeValues(CodeValueGroups.Subdivision, true);
    return this.getSubdivisionsWithCountryNames(subdivs);
  }

  private getSubdivisionsByCountry(countryId: number): CodeValue[] {
    const subdivs = this.codeValueService.getRelatedCodeValues(CodeValueGroups.Subdivision, countryId, CodeValueGroups.Country);
    return this.getSubdivisionsWithCountryNames(subdivs, countryId);
  }

  private getSubdivisionsWithCountryNames(subdivs: CodeValue[], countryId?: number): CodeValue[] {
    const countryNames: { [countryId: number]: string; } = {};

    if (countryId) {
      const country = this.codeValueService.getCodeValue(countryId, 'geo.CodeCountry');
      countryNames[country.id] = country.text;
    } else {
      this.codeValueService.getCodeValues(CodeValueGroups.Country, true)
        .forEach((c: CodeValue) => {
          countryNames[c.id] = c.text;
        });
    }

    return subdivs.map(s => ({ ...s, text: `${s.text}, ${countryNames[s.parentId]}` }));
  }

  private isTempOrSpWorker(): boolean {
    const { WorkerTemp, WorkerCanadianSp } = PhxConstants.UserProfileType;
    return this.userProfileTypeMatchesAny([
      WorkerTemp,
      WorkerCanadianSp
    ]);
  }

  private isW2Worker(): boolean {
    const { WorkerUnitedStatesW2 } = PhxConstants.UserProfileType;
    return this.userProfileTypeMatchesAny([
      WorkerUnitedStatesW2
    ]);
  }

  private userProfileTypeMatchesAny(profileTypes: PhxConstants.UserProfileType[]): boolean {
    if (!this.userProfile) {
      return false;
    }

    const profileTypeId = Number(this.lookupPipe.transform(
      this.workOrder.UserProfileIdWorker,
      [this.userProfile],
      'Id',
      'ProfileTypeId'
    ));

    return profileTypes.find(pt => pt === profileTypeId) !== undefined;
  }

  private setupFormGroupListeners(): void {
    this.coreDetailFormService.organizationIdInternalChange$
      .pipe(debounceTime(100), takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.phxDialogComponent.open();
      });
  }

  private sortByCurrentYearDate(holiday1: Holiday, holiday2: Holiday): number {
    if (holiday1.CurrentYearHoliday == null) {
      return 1;
    }
    if (holiday2.CurrentYearHoliday == null) {
      return -1;
    }
    if (holiday1.CurrentYearHoliday.Date > holiday2.CurrentYearHoliday.Date) {
      return 1;
    } else if (holiday1.CurrentYearHoliday.Date === holiday2.CurrentYearHoliday.Date) {
      return 0;
    } else {
      return -1;
    }
  }
}
